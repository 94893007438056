import "./index.scss";
import "./bootstrap.scss";

import * as bootstrap from 'bootstrap';

declare global {
  interface Window { grecaptcha: any; }
}

const grecaptcha = window?.grecaptcha;
const grecaptchaToken = (action: string) =>
  new Promise((resolve, reject) => {
    grecaptcha.ready(() => {
      grecaptcha.execute('6Ldupg4rAAAAADASI_v2RTgmAb8u55l8Drrb7Xyw', { action: action }).then((token: any) => {
        resolve(token);
      });
    });
  });

const loadingDiv = document.getElementById('loading');
const contentDiv = document.getElementById('content');

const video = document.getElementById('video');

const sendEmailForm = <HTMLFormElement>document.getElementById('sendEmailForm');
const sendMailBtn = <HTMLButtonElement>document.getElementById('sendMailBtn');
const sendMailBtnText = <HTMLButtonElement>document.getElementById('sendMailBtnText');
const sendMailBtnSpinner = <HTMLButtonElement>document.getElementById('sendMailBtnSpinner');

const inputEmail = <HTMLInputElement>document.getElementById('inputEmail');
const inputSubject = <HTMLInputElement>document.getElementById('inputSubject');
const inputMessage = <HTMLTextAreaElement>document.getElementById('inputMessage');

const videoFrame = <HTMLIFrameElement>document.getElementById('videoFrame')
videoFrame.addEventListener('load', () => {
  videoFrame.classList.add('loaded');
});

document.addEventListener('DOMContentLoaded', () => {
  loadingDiv.style.display = 'none';
  contentDiv.style.display = 'inherit';

  const collapseElementList = document.querySelectorAll('.collapse')
  const collapseList = [...collapseElementList].map(collapseEl => new bootstrap.Collapse(collapseEl, { toggle: false }));

  const elements = document.querySelectorAll('img');
  for (let i = 0; i < elements.length; i++) {
    const element = <HTMLImageElement>elements.item(i);
    element.onload = function () {
      element.classList.add('loaded');
    }

    if (element.complete) {
      element.onload(undefined);
    }
  }

  sendEmailForm.addEventListener('submit', (event) => {
    event.preventDefault();
    event.stopPropagation();

    const body = {
      email: inputEmail.value,
      subject: inputSubject.value,
      message: inputMessage.value,
      token: <string>undefined
    }

    if (!body.email || !body.subject || !body.message || body.message.length < 10) {
      sendEmailForm.classList.add('was-validated');
      return;
    }

    sendMailBtnText.classList.add('d-none');
    sendMailBtnSpinner.classList.remove('d-none');
    sendMailBtn.disabled = true;

    inputEmail.disabled = true;
    inputSubject.disabled = true;
    inputMessage.disabled = true;

    return grecaptchaToken('contact').then((token: string) => {
      body.token = token;
      return fetch('/api/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body)
      }).then((response) => response.ok ? response.json() : null).then((data) => {
        inputEmail.value = '';
        inputSubject.value = '';
        inputMessage.value = '';
      }).catch((error) => {
        console.error(error);
      }).finally(() => {
  
        inputEmail.disabled = false;
        inputSubject.disabled = false;
        inputMessage.disabled = false;
  
        sendMailBtn.disabled = false;
        sendMailBtnText.classList.remove('d-none');
        sendMailBtnSpinner.classList.add('d-none');
      })
    });

  }, false);
});